'use strict';

angular.module('enervexSalesappApp').controller('AttachmentsCtrl', function($scope, $rootScope, $stateParams, $state, AttachmentsService, Job, Account, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.job = res;
	});
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	getAttachments();
	function getAttachments() {
		AttachmentsService.fetchAttachments().$promise.then(function(attachments){
			$scope.attachments = attachments;
		});
	}
	$scope.deleteAttachment = function(attachment){
		AttachmentsService.removeAttachment(attachment).$promise.then(function(){
			getAttachments();
		});
	}
	$scope.showFileFormArea = function(){
		$state.go('attachment', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			attachmentId: "new"
		}, {
			inherit: false,
			reload: true
		});
	}
});
